import React, { useEffect, useState } from 'react'
import useDataWrapper from '../Hooks/useDataWrapper';
import ServiceCards from './ServiceCards';

const ServicesSummary = ({filter}) => {

	const [deliveries, setDeliveries] = useState(0);
	const [parcelSend, setParcelSend] = useState(0);
	const [returns, setReturns] = useState(0);
	const [totalVisits, setTotalVisits] = useState(0);

	const { data, isPending, error } = useDataWrapper({
		baseEndpoint : 'Contractors',
		dynamicSegments : ['GetAll',filter.year],
		dataMethod: '',
		filter : filter,
		componentType : 'simple',
	  });
	  useEffect(() => {
    const item = data && data.length > 0 ? data[0] : null;

    const defaultValue = "-";
    if (item && item.MonthlyTransactions.length > 0) {
		let transactions;
		 transactions = item.MonthlyTransactions.length > 1 
			? transactions = item.YearlyTransactions
			: transactions = item.MonthlyTransactions[0].Total;

		if (transactions && transactions !== null) {

			const tmpDeliveries = transactions.Deliveries;
			const tmpParcelSend = transactions.ParcelSend;
			const tmpReturns = transactions.ClientReturns;

			const totalTrans = (parseInt(tmpDeliveries, 10) || 0) + (parseInt(tmpParcelSend, 10) || 0) + (parseInt(tmpReturns, 10) || 0)

			setDeliveries(tmpDeliveries)
			setParcelSend(tmpParcelSend)
			setReturns(tmpReturns)
			setTotalVisits(totalTrans);

		}else {
			resetStates(defaultValue);
		}
    } else {
		resetStates(defaultValue);
    }
  }, [data]);

  const resetStates = (defaultValue) => {
    setDeliveries(defaultValue);
    setParcelSend(defaultValue);
    setReturns(defaultValue);
    setTotalVisits(defaultValue);
};

  return (
	<>
		{isPending && <div>Loading...</div>}
		{error && <div>{ error }</div>}
		{data && (
			<div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
				<ServiceCards
				title = 'Παραδόσεις'
				total = { typeof deliveries === 'number' ? deliveries.toLocaleString('el-GR'): deliveries }
				/>
				<ServiceCards
				title = 'Αποστολές'
				total = { typeof parcelSend === 'number' ? parcelSend.toLocaleString('el-GR'): parcelSend }
				/>
				<ServiceCards
				title = 'Επιστροφές'
				total = { typeof returns === 'number' ? returns.toLocaleString('el-GR'): returns}
				/>
				<ServiceCards
				title= 'Συνολικές επισκέψεις'
				className='bg-blue-thin border-blue-mid'
				total = { totalVisits.toLocaleString('el-GR') }
				/>
			</div>
		)}
	</>
  )
}

export default ServicesSummary