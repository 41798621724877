import React from "react";
import { AiFillEnvironment, AiFillSound } from "react-icons/ai";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";

const SidebarNew = () => {
  return (
    
    <>
    <div className="hidden sm:flex h-screen flex-col justify-between border-e w-72 bg-white">
			<MenuItems className="px-5 py-6"/>
    </div>
    </>
  );
};

export default SidebarNew;
