import React from 'react'
import ApexChartWrapper from './chartslogic/ApexChartWrapper'
import Card from '../Card'
import { data } from 'autoprefixer'

const ReportsChart = ({title, subtitle, baseEndpoint, dynamicSegments,dataMethod,type, filter}) => {
  return (
	<div>
		<Card
		title={title}
		subtitle={subtitle}
		>
		<div className="mt-4">
			<ApexChartWrapper
			baseEndpoint = {baseEndpoint}
			//dynamicSegments={['ContractorCods', filter.year]}
			dynamicSegments={dynamicSegments}
            dataMethod={dataMethod}
            type= {type}
            filter={filter}
			componentType = 'chart'
			height='350'
			/>
		</div>
		</Card>
  	</div>
  )
}


export default ReportsChart