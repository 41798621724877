//Monthly Services
const monthNames = ["Ιαν.", "Φεβ.", "Μαρ.", "Απρ.", "Μαι.", "Ιουν.", "Ιουλ.", "Αυγ.", "Σεπ.", "Οκτ.", "Νοε.", "Δεκ."];

export const servicesData = (servicesData) => {

	let labels, deliveriesData, sendData, returnsData;

	if (Array.isArray(servicesData)) {
		labels = servicesData.map(item => monthNames[item.month - 1]);
		deliveriesData = servicesData.map(item => item.services.deliveries);
		sendData = servicesData.map(item => item.services.parcelSend);
		returnsData = servicesData.map(item => item.services.returns);
	}

	return {
		labels,
		data : [
				{
					name: 'Deliveries',
					data: deliveriesData,
				},
				{
					name: 'Parcel Send',
					data: sendData,
					
				},
				{
					name: 'Returns',
					data: returnsData,
				}
			]
	}
};

export const contractorServices = (servicesData) => {

	let labels = [];
    let deliveriesData = [];
	let sendData = [];
	let returnsData = [];

	servicesData.forEach(item => {
        item.MonthlyTransactions.forEach(month => {
            labels.push(monthNames[month.Month - 1]); // Assuming month starts from 1
            deliveriesData.push(month.Total.Deliveries);
            sendData.push(month.Total.ParcelSend);
            returnsData.push(month.Total.ClientReturns);
        });
    });

	return {
		labels,
		data : [
				{
					name: 'Deliveries',
					data: deliveriesData,
				},
				{
					name: 'Parcel Send',
					data: sendData,
					
				},
				{
					name: 'Returns',
					data: returnsData,
				}
			]
	}
};

export const ratingsData = (ratings) => {
		
	let labels = []
	let ratingData = []

    ratings.forEach(rating => {
        rating.MonthlyRatings.forEach(month => {
            labels.push(monthNames[month.Month - 1]); // Assuming month starts from 1
            ratingData.push(month.OverallAverage);
        });
    });

	return {
		labels,
		data:[
			{
				data:ratingData
			}
		]
	};
};

export const stationServices = (stationServices) => {

	let labels = [];
    let deliveriesData = [];
    let sendData = [];
    let returnsData = [];

    stationServices.forEach(service => {
        service.MonthlyTransactions.forEach(monthlyTransaction => {
            labels.push(monthNames[monthlyTransaction.Month - 1]); // Assuming month starts from 1
            deliveriesData.push(monthlyTransaction.Total.Deliveries);
            sendData.push(monthlyTransaction.Total.ParcelSend);
            returnsData.push(monthlyTransaction.Total.ClientReturns);
        });
    });

	return {
		labels,
		data : [
				{
					name: 'Deliveries',
					data: deliveriesData,
				},
				{
					name: 'Parcel Send',
					data: sendData,
					
				},
				{
					name: 'Returns',
					data: returnsData,
				}
			]
	}
};


export const contractorCods = (contractorCods) => {

	let labels = [];
    let codData = [];

	contractorCods.forEach(contractor => {
		if (contractor.MonthlyCods){
			contractor.MonthlyCods.forEach(codMonth => {
				labels.push(monthNames[codMonth.Month - 1]); // Assuming month starts from 1
				codData.push(codMonth.Cods.CashAmount);
			});
		}
        
    });

	return {
		labels,
		data : [
				{
					name:"Σύνολο Αντικαταβολών",
					data: codData,
				}
			]
	}
};

export const stationCods = (stationCods) => {

	let labels = [];
    let codData = [];

	stationCods.forEach(station => {
		if (station.MonthlyCods){
			station.MonthlyCods.forEach(codMonth => {
				labels.push(monthNames[codMonth.Month - 1]); // Assuming month starts from 1
				codData.push(codMonth.Cods.CashAmount);
			});
		}
        
    });

	return {
		labels,
		data : [
				{
					name:"Σύνολο Αντικαταβολών",
					data: codData,
				}
			]
	}
};

export const actStations = (actStations) => {

	let labels = [];
    let stationData = [];

	actStations.forEach(item => {
            labels.push(monthNames[item.month - 1]); // Assuming month starts from 1
            stationData.push(item.activeStations);
        });

	return {
		labels,
		data : [
				{
					name:"Ενεργά καταστήματα",
					data: stationData,
				}
			]
	}
};





