import React from 'react'
import { Link } from 'react-router-dom'
import MainLayout from '../Components/MainLayout'
import { Empty, SearchNotFoundIcon } from 'rizzui'

const NotFound = () => {
  return (
    <MainLayout>
      <Empty image={<SearchNotFoundIcon />} />
      <h2 className='text-4xl text-center'>404...</h2>
      <h2 className='text-2xl text-center'>Η σελίδα που ζητήσατε δεν βρέθηκε...</h2>
    </MainLayout>

  )
}

export default NotFound