import React from 'react'
import YearMonthFilter from './YearMonthFilter'
import RadioFilters from './RadioFilters'

const CardWithFilter = ({ children, className, title, subtitle, filter, filterUI, onFilterChange, filterType, noPadding}) => {

  return (
	<div className={`rounded-xl  border-card-border border ${noPadding ? 'p-0' : 'p-6'} ${className}`}>
		{(title || filterType) &&
			<div className='flex justify-between mb-6'>
				<div>
					<h2 className='card-title'>{title}</h2>
					<p className='subtitle'>{subtitle}</p>
				</div>
				<div className='self-center'>
					{ filterType === 'dates'&&
					<YearMonthFilter filter={filter} filterUI={filterUI} onFilterChange={onFilterChange}/>
					}
					{ filterType === 'radio' &&
					<RadioFilters filters={filter}/>
					}
				</div>
			</div>
		}
		{ children }
	</div>
  )
}

export default CardWithFilter