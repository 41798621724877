import React from "react";
import { useState } from "react";

//TO DO FETCH MONTHS AND FILTERS

const YearMonthFilter = ({ filterUI, filter, onFilterChange, defaultYear}) => {
  const [month, setMonth] = useState(filter.month);
  const [year, setYear] = useState(defaultYear);

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    onFilterChange(newMonth, filter.year);
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setYear(newYear);
    onFilterChange(filter.month, newYear);
  };
  
  const myYear = new Date().getFullYear();

  return (
    <div>
      <div className="flex space-x-1">
        { (filterUI === 'month' || filterUI === 'both') &&
          <div>
          <label className="text-sm">Μήνας</label>
            <select
              name="HeadlineAct"
              className="w-full rounded-lg border border-card-border p-1 sm:text-sm"
              onChange = {handleMonthChange}
            >
              <option value="">Όλοι</option>
              <option value="1">Ιαν.</option>
              <option value="2">Φεβ.</option>
              <option value="3">Μαρ.</option>
              <option value="4">Απρ.</option>
              <option value="5">Μαι.</option>
              <option value="6">Ιουν.</option>
              <option value="7">Ιουλ.</option>
              <option value="8">Αυγ.</option>
              <option value="9">Σεπ.</option>
              <option value="10">Οκτ.</option>
              <option value="11">Νοε.</option>
              <option value="12">Δεκ.</option>
            </select>
          </div>
        }
        { (filterUI === 'year' || filterUI === 'both') &&
          <div>
            <label className="text-sm">Έτος</label>
            <select
              name="HeadlineAct"
              className="w-full rounded-lg border border-card-border p-1 sm:text-sm"
              onChange = {handleYearChange}
              defaultValue={myYear}
            >
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
        }
      </div>
    </div>
  );
};

YearMonthFilter.defaultProps = {
  defaultYear: new Date().getFullYear()
};

export default YearMonthFilter;
