import React from 'react'
import Card from './Card'
import { BsArrowClockwise , BsSend , BsBoxSeam  } from 'react-icons/bs';

const ServiceCards = ({className, title, subtitle, total}) => {
  return (
	<>
	<Card padding={true} className={className}>
		<div className={`flex lg:justify-between lg:text-right space-x-8 ${className}`}>
			<div className='self-center'> 
				{title  === 'Παραδόσεις' && <BsBoxSeam  className='text-4xl text-main-aqua'/>}
				{title  === 'Αποστολές' && <BsSend   className='text-4xl text-secondary-blue'/>}
				{title  === 'Επιστροφές' && <BsArrowClockwise  className='text-4xl text-orange-300'/>}
			</div>
			<div> 
				<p className='subtitle'>{title}</p>
				<p className='text-2xl font-medium'>{ total }</p>
			</div>
			
		</div>					
	</Card>
		{/* {data && 
		data.map((service)=> (
			<Card key={service.id} padding={true} className={className}>
				<div className={`flex lg:justify-between lg:text-right space-x-8 ${className}`}>
					<div className='self-center'> 
						{service.serviceName === 'Deliveries' && <BsBoxSeam  className='text-4xl text-main-aqua'/>}
						{service.serviceName === 'Parcel Send' && <BsSend   className='text-4xl text-secondary-blue'/>}
						{service.serviceName === 'Returns' && <BsArrowClockwise  className='text-4xl text-orange-300'/>}
					</div>
					<div> 
						<p className='subtitle'>{service.serviceName}</p>
						<p className='text-2xl font-medium'>{ service.yearTotal }</p>
					</div>
					
				</div>
						
			</Card>
		))} */}
	</>
  )
}

export default ServiceCards