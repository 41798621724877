import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Components/AuthContext';
import logo from '../assets/logo_header.svg'
import UniversalContainer from '../Components/UniversalContainer';
import Card from '../Components/Card';
import { Button, Empty, Input, Password, Alert, Text } from "rizzui";

const LoginForm = () => {

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [validationMsg, setValidationMsg] = useState({ email: '', password: '' });
	const [alertOpen, setAlertOpen] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

	const validateField = (value) => {
		if (!value.trim()) return 'Υποχρεωτικό πεδίο';
		return '';
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const emailError = validateField(email);
		const passwordError = validateField(password);
		setValidationMsg({ email: emailError, password: passwordError });

		if (!emailError && !passwordError) {
			try {
				await login(email,password);
				navigate('/');
			}catch (error) {
				console.error('Login failed:', error.message);
				setAlertOpen(true)
			}
			
			
		}
	};

  return (
	<div className='grid justify-items-center '>
			<div className='flex flex-col mt-16 mb-8 text-center'>
				<img className='mt-4' src={logo}/>
			</div>
			<Card background={true} className='flex flex-col w-full sm:max-w-96 ' >
				<div className='flex flex-col py-6'>
						{ 
							alertOpen && 
							<Alert icon = {<></>} barClassName='hidden' className='mb-4' color="danger" size='sm' rounded="md" variant="flat" onClose={() => setAlertOpen(false)}>
								<Text className="">Λανθασμένο όνομα χρήστη ή κωδικός.</Text>
							</Alert>
						}
					<form className='min-w-100' onSubmit={handleSubmit}>
						<div className='grid grid-cols-1 gap-6 mt-3 mb-8 auto-cols-max'>
							<Input
							type='text'
							value={email}
							onChange={(e) => {
								const newEmail = e.target.value;
								setEmail(newEmail);
								setValidationMsg((prev) => ({
								  ...prev,
								  email: validateField(newEmail),
								}));
							}}
							placeholder="Όνομα χρήστη ή email"
							error={validationMsg.email}
							errorClassName = 'text-xs pl-4 mt-2'
							inputClassName={`base-input-class ${validationMsg.email ? 'hover:border-rose-500 bg-rose-100 border-red-500 focus:!border-red-500' : 'border-gray-300'}`} // Conditional class application
							/>
							
							<Password
							placeholder="Enter your password"
							value={password}
							onChange={(e) => {
								const newPassword = e.target.value;
								setPassword(newPassword);
								setValidationMsg((prev) => ({
								  ...prev,
								  password: validateField(newPassword),
								}));
							}}
							error={validationMsg.password}
							errorClassName = 'text-xs pl-4 mt-2'
							inputClassName={`base-input-class ${validationMsg.password ? 'hover:border-rose-500 bg-rose-100 border-red-500 focus:!border-red-500' : 'border-gray-300'}`} // Conditional class applicatio
							/>
						</div>
						<Button className='bg-main-aqua min-w-full hover:bg-main-aqua hover:opacity-80' type='submit'>Σύνδεση</Button>
					</form>
				</div>
			</Card>
			<div className='mt-6 text-xs'>
				<Link target="_blank"  to="https://platform.cleverpoint.gr/portal/Account/ForgotPassword">
					<p className='hover:text-main-aqua'>Ξέχασα τον κωδικό μου</p>
				</Link>
			</div>
			
		</div>
  )
}

export default LoginForm