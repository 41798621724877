import React from 'react'
import UniversalContainer from '../Components/UniversalContainer'
import ApexChartManual from '../Components/Charts/ApexChartManual'
import ServicesVolton from '../Components/ServicesVolton'
import RatingsSummaryManual from '../Components/RatingsSummaryManual'
import TopStoresManual from '../Components/TopStoresManual'
import Card from '../Components/Card'

const VoltonData = () => {

	const deliveryData2023 = 
	{
		labels: ['Ιουν','Ιουλ.', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ', 'Ιαν(2024)', 'Φεβ(2024)'],
		data : [
				{
					name: 'Παραδόσεις',
					data: [3,117, 163, 428, 936, 951, 1070, 1267, 796]
				},
				{
					name: 'Επιστροφές',
					data: [3,36, 42, 31, 68, 59, 80, 85, 34]
				}
			]
	}

  return (
	<div className='overflow-x-hidden p-2 sm:p-4 md:p-8 lg:px-64 bg-white'>
		<h2 className='card-title'>Σύνοψη</h2>
			<ServicesVolton />
		
		<Card className='my-8' title='Διάγραμμα συναλλαγών'>
			<ApexChartManual chartData={deliveryData2023} height='600' type='bar'></ApexChartManual>
		</Card>
		
		<div className='my-8'>
			<RatingsSummaryManual/>
		</div>
		
		<TopStoresManual/>
	</div>
		
  )
}

export default VoltonData