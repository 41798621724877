import React, { useState } from 'react'

const ContractorData = () => {


	const [contractorName, setContractorName] = useState('Δίκτυο αυτόνομων καταστημάτων Clever Point');
	const name = localStorage.getItem('contractorName')
  return (
	<div className='mb-10'>{name}</div>
  )
}

export default ContractorData