import React, { useState } from "react";
import UniversalContainer from "./UniversalContainer";
import Sidebar from "./Sidebar";
import SidebarNew from "./SidebarNew";
import { Drawer, Alert, Button, Tooltip, Text } from "rizzui";
import DrawerMenu from "./DrawerMenu";
import MobileNavigation from "./MobileNavigation";
import ContractorData from "./ContractorData";

const MainLayout = ({ children }) => {

  
  return (
    <div className="grid grid-cols-1 lg:grid-cols-sidebar-layout h-screen">
      <div className="hidden lg:block">
        <SidebarNew />
      </div>
      <div className="block lg:hidden">
          <MobileNavigation/>
      </div>
      <UniversalContainer>
        <ContractorData></ContractorData>
        {children}
      </UniversalContainer>
    </div>
  );
};

export default MainLayout;
