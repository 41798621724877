import React, { useEffect, useState } from 'react'
import Card from './Card'
import { Announcement } from 'rizzui'
import useDataWrapper from '../Hooks/useDataWrapper';

const AverageDeliveryTime = ({filter}) => {

	// Initialize state variables
	const [time, setTime] = useState('-');

	const { data, isPending, error } = useDataWrapper({

		baseEndpoint : 'Contractors',
		dynamicSegments : ['DeliveryTime',filter.year],
		dataMethod: '',
		filter : filter,
		componentType : 'simple'
	});

	  

	useEffect(() => {

		const item = data && data.length > 0 ? data[0] : null;

		const defaultTimeValue = '-';

		if (item && item.MonthlyTransactions ) {

			let avgDeliveryTime = defaultTimeValue;

		if (item.MonthlyTransactions.length > 1) {
			avgDeliveryTime = item.YearDeliveryTime ?? defaultTimeValue;
			} else if (item.MonthlyTransactions.length === 1) {
			avgDeliveryTime = item.MonthlyTransactions[0].AverageDeliveryTime ?? defaultTimeValue;
		}
			setTime(avgDeliveryTime);
		}else {
			setTime(defaultTimeValue);
		}
	
	}, [data]);

	  	if (isPending) return <div>Loading...</div>;
		if (error) return <div>Error: {error}</div>;

	  return (
		data && (
		<>
		<div>
		<Card>
			<div>
				<div className='flex justify-between'>
					<div className='space-y-4'>
						<p className='subtilte'>Μέσος χρόνος παράδοσης</p>
						<h2 className='text-4xl'>{time} <span className='subtitle'>{time > 1? "ημέρες" : 'ημέρα'}</span></h2>
					</div>
				</div>
				<p className='subtitle'>Από την ημέρα που το δέμα φτάνει στο σημείο</p>
			</div>
		</Card>
	</div>
	</>)
	
  )
}

export default AverageDeliveryTime