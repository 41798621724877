import React, { useEffect, useState } from "react";
import Card from "./Card";
import { IoIosStar } from "react-icons/io";
import useDataWrapper from "../Hooks/useDataWrapper";

const RatingsSummaryManual = ({}) => {
  
  return (
      <>
        <div>
          <Card>
            <div className="flex justify-between mb-2">
              <div>
                <p className="subtilte">Συνολικές Αξιολογήσεις</p>
                <h2 className="card-title text-2xl font-semibold">
                  760
                </h2>
              </div>
              <div className="text-right">
                <p className="subtilte">M.O.</p>
                <div className="flex items-center gap-2 content-center">
                  <IoIosStar className="text-2xl text-amber-400" />
                  <h2 className="card-title text-2xl font-bold">
                    4.84
                  </h2>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="flex justify-between mt-2">
              <div>
                <p className="subtitle">Ποιότητα εξυπηρέτησης</p>
                <p className="card-title text-xl font-medium">
                4.84
                </p>
              </div>
              <div>
                <p className="subtitle">Συμπεριφορά υπαλλήλων</p>
                <p className="card-title text-xl font-medium">4.86</p>
              </div>
              <div>
                <p className="subtitle">Ταχύτητα εξυπηρέτησης</p>
                <p className="card-title text-xl font-medium">4.83</p>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
};

export default RatingsSummaryManual;
