import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { useAuth } from "./AuthContext";
import logo from '../assets/logo_header.svg'

const MenuItems = ({ className }) => {
  const menuPages = [
    {
      path: "/",
      title: "Αρχική",
    },
    {
      path: "/reports",
      title: "Αναφορές",
    },
    {
      path: "/stations",
      title: "Καταστήματα",
    }
  ];

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login'); 
  };


  return (
    <div className={`flex h-screen flex-col justify-between ${className}`}>
      <div>
        <div className="h-10 w-40 place-content-center px-2">
          <img src={logo} />
        </div>
        <ul className="mt-6 space-y-1">
		
          {menuPages.map((item, index) => (
            <Link to={item.path} key={index}>
              <li className="flex items-center rounded-md text-white">
                <p className="grow rounded-lg px-4 py-2 text-sm font-regular text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                  {item.title}
                </p>
              </li>
            </Link>
          ))}
        </ul>
      </div>

      <div className="sticky inset-x-0 bottom-0 border-t border-gray-100" onClick={handleLogout}>
        <div
          href="#"
          className="cursor-pointer flex items-center gap-2 bg-white p-4 hover:bg-gray-50"
        >
          <MdLogout className="text-red" />
          <div>
            <div className="text-xs" >
              <p className="block font-medium text-red">Αποσύνδεση</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuItems;
