import React, { useState } from 'react'
import useFetch from '../Hooks/useFetch'
import Card from './Card'
import { Link } from 'react-router-dom'
import useDataWrapper from '../Hooks/useDataWrapper'
import { Empty, SearchNotFoundIcon, AdvancedRadio } from "rizzui";


const TopStores = ({filter}) => {

	// const {filter, setFilter} = useState('month')
	const [serviceType, setServiceType] = useState('DELIVERIES')

	const { data, isPending, error } = useDataWrapper({
		baseEndpoint : 'Contractors',
		dynamicSegments : ['TopStores',filter.year, serviceType],
		queryParams : {filter},
		dataMethod: '',
		filter : filter,
		serviceType : serviceType,
		componentType : 'simple',
	  });

	  const handleSelectionChange = (event) => {
		setServiceType(event.target.value);
    };

	

  return (
	<div className='gris grid-cols-2 text-left'>
		{isPending && <div>Loading...</div>}
		{error && <div>{ error }</div>}
		{data && data.length < 1 && (
			<>
			<Card>
				<div className='flex flex-wrap justify-between gap-1 mb-5'>
					<div>
						<h2 className='card-title'>Καλύτερα Καταστήματα</h2>
						<p className='subtitle'>Ανά τύπο υπηρεσίας</p>
					</div>
					<div className='flex flex-wrap justify-between gap-1'>
						<div>
							<AdvancedRadio name='Παραλαβές' value='DELIVERIES' alignment='center' size='sm' className='text-sm ' checked={serviceType === 'DELIVERIES'}
							onChange={handleSelectionChange} >Παραλαβές</AdvancedRadio>
						</div>
						<div>
							<AdvancedRadio name='Αποστολές' value='PARCEL_SEND' alignment='center' size= 'sm' className='text-sm' checked={serviceType === 'PARCEL_SEND'}
							onChange={handleSelectionChange}>Αποστολές</AdvancedRadio>
						</div>
						<div>
							<AdvancedRadio name='Επιστροφές' value='CLIENTRETURNS' alignment='center' size='sm' className='text-sm' checked={serviceType === 'CLIENTRETURNS'}
							onChange={handleSelectionChange}>Επιστροφές</AdvancedRadio>
						</div>
					</div>
				</div>
				<div className=''> {/** Table parent div**/}
					<h2 className='subtitle text-center'>Δεν βρέθηκαν αποτελέσματα...</h2>
				</div>
			</Card>
			</>
		)}


		{data &&  data.length > 0 && (
		<>
		<Card>
			<div className='flex flex-wrap justify-between gap-1 mb-5'>
				<div>
					<h2 className='card-title'>Καλύτερα Καταστήματα</h2>
					<p className='subtitle'>Ανά τύπο υπηρεσίας</p>
				</div>
				<div className='flex flex-wrap justify-between gap-1'>
					<div>
						<AdvancedRadio name='Παραλαβές' value='DELIVERIES' alignment='center' size='sm' className='text-sm ' checked={serviceType === 'DELIVERIES'}
						onChange={handleSelectionChange} >Παραλαβές</AdvancedRadio>
					</div>
					<div>
						<AdvancedRadio name='Αποστολές' value='PARCEL_SEND' alignment='center' size= 'sm' className='text-sm' checked={serviceType === 'PARCEL_SEND'}
						onChange={handleSelectionChange}>Αποστολές</AdvancedRadio>
					</div>
					<div>
						<AdvancedRadio name='Επιστροφές' value='CLIENTRETURNS' alignment='center' size='sm' className='text-sm' checked={serviceType === 'CLIENTRETURNS'}
						onChange={handleSelectionChange}>Επιστροφές</AdvancedRadio>
					</div>
				</div>
			</div>
			<div className='border rounded-sm'> {/** Table parent div**/}
				<div className='grid grid-cols-2 text-left gap-3 p-3 bg-petrol-thin'>
					<div className='text-sm'>Κατάστημα</div>
					<div>
					{
						serviceType === 'DELIVERIES' ? 'Παραδόσεις' : 
						serviceType === 'PARCEL_SEND' ? 'Αποστολές' : 
						serviceType === 'CLIENTRETURNS' ? 'Επιστροφές' : 
						'N/A' 
					}
					</div>
				</div>
				<div>
					{data.map((topStores) => (
						<Link key={topStores.station_id} className=' cursor-pointer' to={`/station/${topStores.prefix}`}>
							<div className='border-t p-3  hover:bg-blue-light cursor-pointer' >
								<div className='grid grid-cols-2 text-left gap-3'>
									<div>
										<h2 className='text-sm'>{ topStores.station_short_name } ({topStores.prefix})</h2>
									</div>
									<div>
										<p className='text-md font-bold'>
											{ topStores.total}
										</p>
									</div>
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</Card>
		</>
	)}
	</div>
  )
}

export default TopStores

