import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';




const CheckAuth = () => {
  
	const {user} = useAuth();
	const location = useLocation();

	const {logout} = useAuth();

	const checkExpiration = () => {
		const expirationTime = localStorage.getItem('authExpiration');
		const now = new Date().getTime();
		if (now > parseInt(expirationTime, 10)) {
		  logout();
		}
	  };

	  useEffect(() => {
		checkExpiration();
	  })
	

	if (!user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Outlet/>;

}

export default CheckAuth;


