import React from 'react'
import LineChart from './LineChart';
import BarChart from './BarChart';


const ApexChartManual = ({chartData, title,type,height}) => {
	
	const data = true;
	const chartSeries = chartData
	
	return (
		<div>
		{
		(data && type==='line') && <LineChart chartSeries={chartSeries}  title={title} type={type}/>
		}
		{
		(data && type==='bar') && <BarChart chartSeries={chartSeries}  title={title} type={type} height={height}/>
		}
		{
		(data && type==='area') && <LineChart chartSeries={chartSeries}  title={title} type={type}/>
		}
		</div>
	)
}


export default ApexChartManual