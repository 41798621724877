import React from 'react'

const UniversalContainer = ({children}) => {
  return (
	<div className='overflow-x-hidden p-2 sm:p-4 md:p-8 lg:p-16 bg-white'>
		{children}
	</div>
  )
}

export default UniversalContainer