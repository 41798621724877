import { useState } from 'react';

const useFilter = (initialFilters) => {
  
  const [filters, setFilters] = useState(initialFilters);
  
  const handleFilterChange = (newFilter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilter
    }));
  };

  return [filters, handleFilterChange];
};

export default useFilter;
