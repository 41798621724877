import React from 'react'

const RadioFilters = ({filters}) => {


  return (
	<fieldset className="flex flex-wrap gap-3">
  <legend className="sr-only">Color</legend>

  {filters && 
		filters.map((x, index)=> (
			<div key={x} padding={true}>
				<div>
    				<input type="radio" name="ColorOption" value={x} id={x} className="peer hidden" defaultChecked={index === 0}/>

    				<label
      					for={x}
      					className="flex cursor-pointer items-center justify-center rounded-md border border-gray-100 bg-white px-3 py-2 text-gray-900 hover:border-gray-200 peer-checked:border-blue-500 peer-checked:bg-blue-500 peer-checked:text-white"
    				>
      				<p className="text-xs">{x}</p>
    				</label>
  				</div>
			</div>
			
		))}
</fieldset>
  )
}

export default RadioFilters