// src/pages/LoginPage.js
import React from 'react';
import UniversalContainer from '../Components/UniversalContainer';
import LoginForm from '../Components/LoginForm';

const Login = () => {

  return (
	<UniversalContainer>
    <LoginForm/>
	</UniversalContainer>
  );
};

export default Login;

