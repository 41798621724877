import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Components/MainLayout";
import useFetch from "../Hooks/useFetch";
import { Combobox } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../Components/Card";
import { Input } from "rizzui";

const Stations = () => {
  const headers = {
    Authorization: "Token " + String(localStorage.getItem("authToken")),
  };

  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const cardRef = useRef(null);

  const contractorId = String(localStorage.getItem("ContractorId"));

  const API_URL = `https://test.cleverpoint.gr/api/v1/Stations/GetDeliveryStationsByContractor/${contractorId}`;

  const { data, isPending, error } = useFetch(API_URL, null, null, headers);

  const [stations, setStations] = useState([]);

  useEffect(() => {
    if (data && data.Content) {
      setStations(data.Content);
    }
  }, [data]);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const filteredItems = stations
    ? stations.filter((station) => {
        return (
          station.Name.toLowerCase().includes(query.toLowerCase()) ||
          station.AddressLine1.toLowerCase().includes(query.toLowerCase()) ||
          station.Prefix.toLowerCase().includes(query.toLowerCase()) ||
          station.ZipCode.toLowerCase().includes(query.toLowerCase()) ||
          station.City.toLowerCase().includes(query.toLowerCase())
        );
      })
    : []; 

  return (
    <MainLayout>
      <h2 className="text-2xl mb-6">Τα καταστήματα του δικτύου</h2>
      <div className="grid lg:grid-cols-2 gap-4"></div>
	  <div>
		{isPending && (<div>Loading...</div>)}
		{error && (<div>Error : {error}</div>)}
		
		{data && (
			
			<Input
			  type="text"
			  placeholder="Αναζήτηση με Κωδικό σημείου, Όνομα, Τ.Κ., Πόλη "
			  value={query}
			  onChange={(e) => setQuery(e.target.value)}
			  onFocus={() => setIsInputFocused(true)}
			  onClear={() => {
				setIsInputFocused(false);
				setQuery("");
			  }}
			  clearable
			/>
		)}
        {isInputFocused && query.trim() !== "" && (
          <ul className="h-[400px] overflow-y-auto cursor-pointer">
            {filteredItems.map((item) => (
              <li
                key={item.Prefix}
                onClick={() => navigate(`/station/${item.Prefix}`)}
              >
                <div className="bg-zinc-50 p-3 border-b-2 border-zinc-300 hover:bg-zinc-200">
                  <h2 className="font-medium text-md text-main-aqua">
                    {item.Prefix} - {item.Name}
                  </h2>
                  <p className="">{item.AddressLine1}</p>
                  <p className="text-zinc-500">
                    {item.City} {item.ZipCode}
                  </p>
                </div>
              </li>
            ))}
          </ul>
		  	  
        )}
      </div>
    </MainLayout>
  );
};

export default Stations;
