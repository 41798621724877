import React, { useState } from 'react'
import { Drawer,Button, ActionIcon } from 'rizzui'
import SidebarNew from './SidebarNew'
import MenuItems from './MenuItems'
import { CgClose,CgMenuLeftAlt } from "react-icons/cg";

const DrawerMenu = ({}) => {

  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    placement: "right",
    size: "sm"
  });

  const openDrawer = () => {
    setDrawerState({ ...drawerState, isOpen: true });
  };

  const closeDrawer = () => {
    setDrawerState({ ...drawerState, isOpen: false });
  };

	return (
    <>
    <ActionIcon className='p-0'variant="text" onClick={openDrawer}>
        <CgMenuLeftAlt  className="w-5 h-5" />
      </ActionIcon>
    <Drawer
      isOpen={drawerState.isOpen}
      placement={drawerState.placement}
      size={drawerState.size}
      onClose={closeDrawer}
    >
      <div className='flex justify-end pt-5 pr-5'>
      <ActionIcon variant="text" onClick={closeDrawer}>
        <CgClose  className="w-5 h-5" />
      </ActionIcon>
      </div>
      <MenuItems className="pl-5 pt-1"/>
    </Drawer>
  </>
	
  )
}

export default DrawerMenu