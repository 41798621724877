import React, { useState } from 'react'
import useFetch from '../Hooks/useFetch'
import Card from './Card'
import { Link } from 'react-router-dom'
import useDataWrapper from '../Hooks/useDataWrapper'
import { Empty, SearchNotFoundIcon, AdvancedRadio } from "rizzui";


const TopStoresManual = () => {

	const tabledata = [
		{
			stationName : 'VOLTON SHOP ΛΑΡΙΣΑ',
			deliveries : 786,
			shipments : 0,
			returns : 29,
			totalVisits : 815
		},
		{
			stationName : 'VOLTON SHOP ΒΟΛΟΣ',
			deliveries : 747,
			shipments : 0,
			returns : 59,
			totalVisits : 806
		},
		{
			stationName : 'VOLTON SHOP ΑΓΙΟΣ ΣΤΕΦΑΝΟΣ',
			deliveries : 499,
			shipments : 0,
			returns : 16,
			totalVisits : 515
		},
		{
			stationName : 'VOLTON SHOP ΜΗΤΡΟΠΟΛΕΩΣ',
			deliveries : 434,
			shipments : 0,
			returns : 51,
			totalVisits : 485
		},
		{
			stationName : 'VOLTON SHOP ΚΑΒΑΛΑΣ',
			deliveries : 354,
			shipments : 0,
			returns : 104,
			totalVisits : 458
		},
		{
			stationName : 'VOLTON SHOP ΠΕΡΙΣΤΕΡΙ',
			deliveries : 442,
			shipments : 0,
			returns : 10,
			totalVisits : 452
		},
		{
			stationName : 'VOLTON SHOP MAROUSI',
			deliveries : 397,
			shipments : 0,
			returns : 25,
			totalVisits : 422
		},
		{
			stationName : 'VOLTON SHOP ΠΕΙΡΑΙΑ',
			deliveries : 384,
			shipments : 0,
			returns : 22,
			totalVisits : 406
		},
		{
			stationName : 'VOLTON SHOP ΣΥΓΓΡΟΥ',
			deliveries : 303,
			shipments : 0,
			returns : 11,
			totalVisits : 314
		},
		{
			stationName : 'VOLTON SHOP ΚΕΡΚΥΡΑ',
			deliveries : 187,
			shipments : 0,
			returns : 21,
			totalVisits : 208
		},
		{
			stationName : 'VOLTON SHOP ΚΑΛΑΜΑΡΙΑΣ',
			deliveries : 172,
			shipments : 0,
			returns : 6,
			totalVisits : 178
		},
		{
			stationName : 'VOLTON SHOP ΙΩΑΝΝΙΝΩΝ',
			deliveries : 158,
			shipments : 0,
			returns : 17,
			totalVisits : 175
		},
		{
			stationName : 'VOLTON SHOP ΚΑΛΑΜΑΤΑ',
			deliveries : 169,
			shipments : 0,
			returns : 3,
			totalVisits : 172
		},
		{
			stationName : 'VOLTON SHOP ΣΕΡΡΕΣ',
			deliveries : 150,
			shipments : 0,
			returns : 17,
			totalVisits : 167
		},
		{
			stationName : 'VOLTON SHOP ΠΑΤΡΑΣ',
			deliveries : 104,
			shipments : 0,
			returns : 22,
			totalVisits : 126
		},
		{
			stationName : 'VOLTON SHOP ΚΑΤΕΡΙΝΗΣ',
			deliveries : 108,
			shipments : 0,
			returns : 1,
			totalVisits : 109
		},
		{
			stationName : 'VOLTON SHOP ΤΡΙΠΟΛΗ',
			deliveries : 105,
			shipments : 0,
			returns : 4,
			totalVisits : 109
		},
		{
			stationName : 'VOLTON SHOP ΚΟΡΙΝΘΟΣ',
			deliveries : 90,
			shipments : 0,
			returns : 5,
			totalVisits : 95
		},
		{
			stationName : 'VOLTON SHOP ΗΡΑΚΛΕΙΟΥ',
			deliveries : 85,
			shipments : 0,
			returns : 8,
			totalVisits : 93
		},
		{
			stationName : 'VOLTON SHOP ΜΑΡΤΥΡΩΝ',
			deliveries : 47,
			shipments : 0,
			returns : 3,
			totalVisits : 50
		},
		{
			stationName : 'VOLTON SHOP ΣΚΥΔΡΑ',
			deliveries : 6,
			shipments : 0,
			returns : 0,
			totalVisits : 6
		},
		{
			stationName : 'VOLTON SHOP ΙΤΕΑ',
			deliveries : 4,
			shipments : 0,
			returns : 0,
			totalVisits : 4
		},
]

	

  return (
	<div className='gris grid-cols-2 text-left'>
		
		<Card>
			<div className='flex flex-wrap justify-between gap-1 mb-5'>
				<div>
					<h2 className='card-title'>Στατιστικά ανά κατάστημα</h2>
					<p className='subtitle'>Κινήσεις & Συνολική Επισκεψιμότητα</p>
				</div>
			</div>
			<div className='border rounded-sm'> {/** Table parent div**/}
				<div className='grid grid-cols-5 text-center gap-3 p-3 bg-petrol-thin'>
					<div className='text-left'>Κατάστημα</div>
					<div>
						Παραδόσεις
					</div>
					<div>
						Αποστολές
					</div>
					<div>
						Επιστροφές			
					</div>
					<div>
						Συνολικές Επισκέψεις
					</div>
				</div>
				{tabledata.map((tabledata) => (
						<div className='border-t p-3  hover:bg-blue-light cursor-pointer' >
						<div className='grid grid-cols-5 text-center gap-3'>
							<div>
								<p className='text-blue-mid text-left '>{tabledata.stationName}</p>
							</div>
							<div>
								<p>{tabledata.deliveries}</p>
							</div>
							<div>
								<p>{tabledata.shipments}</p>
							</div>
							<div>
								<p>{tabledata.returns}</p>
							</div>
							<div>
								<p className='text-md font-bold'>
								{tabledata.deliveries + tabledata.returns + tabledata.shipments}
								</p>
							</div>
						</div>
					</div>
					))}
				<div>
							
				</div>
			</div>
		</Card>
	</div>
  )
}

export default TopStoresManual

