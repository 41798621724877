import React from 'react'
import YearMonthFilter from './YearMonthFilter'

const Card = ({ children, background, title, subtitle, className }) => {

  return (
	<div className={`rounded-xl p-6 border-card-border border  ${background ? 'border-slate-200 shadow-custom-cards' : 'bg-none'} ${className}`}>
		{title &&
			<div className='mb-6'>
				<h2 className='card-title'>{title}</h2>
				<p className='subtitle'>{subtitle}</p>
			</div>
		}
		{ children }
	</div>
	
  )
}

export default Card