import React, { useEffect, useState } from 'react'
import useDataWrapper from '../Hooks/useDataWrapper';
import ServiceCards from './ServiceCards';

const ServicesVolton = ({data}) => {

	return (
	<>
			<div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
				<ServiceCards
				title = 'Παραδόσεις'
				total = '5731'
				/>
				<ServiceCards
				title = 'Αποστολές'
				total = '0'
				/>
				<ServiceCards
				title = 'Επιστροφές'
				total = '434'
				/>
				<ServiceCards
				title= 'Συνολικές επισκέψεις'
				className='bg-blue-thin border-blue-mid'
				total = '6165'
				/>
			</div>
	</>
  )
}

export default ServicesVolton;