import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../Hooks/useFetch';
import Card from '../Components/Card';
import MainLayout from '../Components/MainLayout';
import ReportsChart from '../Components/Charts/ReportsChart';
import useFilter from '../Hooks/useFilter';
import YearMonthFilter from '../Components/YearMonthFilter';
import RatingsSummary from '../Components/RatingsSummary';

const StationData = () => {
    const { id } = useParams();

    let dt = new Date();
    let myYear = dt.getFullYear();

    const [filter, handleFilterChange] = useFilter({
        month: '',
        year: YearMonthFilter.defaultProps.defaultYear,
    });

    const onFilterChange = (newMonth, newYear) => {
        handleFilterChange({ month: newMonth, year: newYear });
    };

    return (
        <MainLayout>
            <div>
                <h2>Station Data</h2>
                <p>{id}</p>
            </div>
            <div className="flex justify-end mt-5">
                <YearMonthFilter
                    filterUI="both"
                    filterType="dates"
                    onFilterChange={onFilterChange}
                    filter={filter}
                />
            </div>
            <div className="mt-5">
                <div className="grid md:grid-cols-2 gap-4">
                    <ReportsChart
                        title="Υπηρεσίες"
                        subtitle="Σύνολο κινήσεων ανά μήνα"
                        baseEndpoint="Stations"
                        dynamicSegments={['StationServices', id, filter.year]}
                        dataMethod="stationServices"
                        type="bar"
                        filter={filter}
                        componentType="chart"
                    />
                    <div>
                        <ReportsChart
                            title="Αντικαταβολές"
                            subtitle="Σύνολο διαχείρισης αντικαταβολών ανά μήνα"
                            baseEndpoint="Stations"
                            dynamicSegments={['StationCods', id, filter.year]}
                            dataMethod="stationCods"
                            type="line"
                            filter={filter}
                            componentType="chart"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <RatingsSummary
                    baseEndpoint="Stations"
                    dynamicSegments={['StationRatings', id, filter.year]}
                    dataMethod="stationRatings"
                    componentType="simple"
                    filter={filter}
                />
            </div>
        </MainLayout>
    );
};

export default StationData;
