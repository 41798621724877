import MainLayout from '../Components/MainLayout';
import YearMonthFilter from '../Components/YearMonthFilter';
import useFilter from '../Hooks/useFilter';
import ReportsChart from '../Components/Charts/ReportsChart';
import TopStores from '../Components/TopStores';
import RatingsSummary from '../Components/RatingsSummary';
import AverageDeliveryTime from '../Components/AverageDeliveryTime';

const Reports = () => {

    const [filter, handleFilterChange] = useFilter({
        month: '',
        year: YearMonthFilter.defaultProps.defaultYear,
    });

    const onFilterChange = (newMonth, newYear) => {
        handleFilterChange({ month: newMonth, year: newYear });
    };

    return (
        <MainLayout>
            <div className="flex justify-end">
                <YearMonthFilter
                    filterUI="both"
                    filterType="dates"
                    onFilterChange={onFilterChange}
                    filter={filter}
                />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                <div className="md:col-span-2">
                    <RatingsSummary
                        baseEndpoint="Contractors"
                        dynamicSegments={['ContractorRatings', filter.year]}
                        dataMethod=""
                        componentType="simple"
                        filter={filter}
                    />
                </div>
                <div>
                    <AverageDeliveryTime filter={filter} />
                </div>
            </div>

            <div>
                <div className="my-7">
                    <TopStores filter={filter} />
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mt-5">
                <ReportsChart
                    title="Υπηρεσίες"
                    subtitle="Σύνολο κινήσεων ανά μήνα"
                    baseEndpoint="Contractors"
                    dynamicSegments={['GetAll', filter.year]}
                    dataMethod="contractorServices"
                    type="bar"
                    filter={filter}
                    componentType="chart"
                />
                <ReportsChart
                    title="Αντικαταβολές"
                    subtitle="Συνολικό ποσό διαχείρισης αντικαταβολών ανά μήνα"
                    baseEndpoint="Contractors"
                    dynamicSegments={['ContractorCods', filter.year]}
                    dataMethod="contractorCods"
                    type="bar"
                    filter={filter}
                    componentType="chart"
                />
            </div>
        </MainLayout>
    );
};

export default Reports;
