import React, { useState } from 'react'
import DrawerMenu from './DrawerMenu';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_header.svg'

const MobileNavigation = () => {

	const [drawerSate, setDrawerState] = useState({
		isOpen: false,
		placement: "left",
		size: "sm",
	  });

  return (
	<div className='flex justify-between items-center p-2'>
		<Link to='/'>
			<div className=" flex justify-center items-center w-40">
				<img src={logo}/>
			</div>
		</Link>
		<DrawerMenu drawerState={drawerSate} setDrawerState={setDrawerState}/>
	</div>
  )
}

export default MobileNavigation