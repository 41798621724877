import React, { useEffect, useState } from 'react'

const useFetch = (API_URL, filter, serviceType, headersInput) => {

	const [data, setData] = useState(null);
	const [isPending, setIsPending] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {

		const abortContr = new AbortController();
		setTimeout(() => {
			fetch(API_URL, {
					signal: abortContr.signal,
					headers : headersInput
				})
				.then(res => {
					if (!res.ok) {
						throw Error('could not fetch the data for that resource')	
					}
					return res.json();
				})
				.then((data) => {
					setData(data);
					setError(null);
					setIsPending(false);
				})
				.catch(error => {
					if (error.name === 'AbortError') {
						console.log('fetch aborted');
					} else {
						setError(error.message);
						setIsPending(false);
						console.log(error.message);
					}
				})
				}, 0)

				return () => abortContr.abort();
	}, [API_URL], [filter], [serviceType]);

	return {data, isPending, error}
}

export default useFetch;