import React from 'react';
import CardWithFilter from './CardWithFilter';
import useFilter from '../Hooks/useFilter';
import ServicesSummary from './ServicesSummary';
import YearMonthFilter from './YearMonthFilter';

const ServicesSummaryFilter = () => {
    const [filter, handleFilterChange] = useFilter({
        month: '',
        year: YearMonthFilter.defaultProps.defaultYear,
    });

    return (
        <div>
            <CardWithFilter
                title="Γρήγορη Σύνοψη"
                subtitle="Οι συναλλαγές σας με μια ματιά"
                filterUI="both"
                filterType="dates"
                filter={filter}
                onFilterChange={(newMonth, newYear) =>
                    handleFilterChange({ month: newMonth, year: newYear })
                }
                noPadding={true}
                className="border-none"
            >
                <ServicesSummary filter={filter} />
            </CardWithFilter>
        </div>
    );
};

export default ServicesSummaryFilter;
