import React from 'react'
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ chartSeries, title, type, height  }) => {
  const series = chartSeries.data

	const options = {
    plotOptions: {
        bar: {
            borderRadius: 1,
            horizontal: false,
        }
    },
    dataLabels: {
      enabled: false
    },
		title: {
			text: title,
			align: 'left'
		  },
        xaxis: {
            categories: chartSeries.labels
        },
		yaxis: {
            show:true
        }
		  }


    return (
		<>
      <ReactApexChart options={options} series={series} type={type} height={height}/>
		</>
    );

}
export default BarChart