import React, { useEffect, useState } from "react";
import TopStores from "../Components/TopStores";
import YearMonthFilter from "../Components/YearMonthFilter";
import ChartJsLogic from "../Components/Charts/chartslogic/ChartJsLogic";
import ApexChartWrapper from "../Components/Charts/chartslogic/ApexChartWrapper";
import ChartJsChart from "../Components/Charts/ChartJsChart";
import RadioFilters from "../Components/RadioFilters";
import { Tab } from "rizzui";
import useFilter from "../Hooks/useFilter";
import ServicesSummary from "../Components/ServicesSummary";

/*
1. Single Object State for All Diagrams:
Instead of separate states for each diagram, use a single state object that holds the filter state for all diagrams. This is similar to the previously discussed approach but more generalized.

const [diagramFilters, setDiagramFilters] = useState({
    diagram1: { month: '', year: '' },
    diagram2: { month: '', year: '' },
    // and so on for each diagram
});

const handleFilterChange = (diagramId, month, year) => {
    setDiagramFilters(prev => ({ ...prev, [diagramId]: { month, year }}));
};
*/

const TestPage = () => {
  // const [filter, setFilter] = useState({ month: '', year: '' });
  // const [filterTwo, setFilterTwo] = useState({ month: '', year: '' });

  // const handleFilterChange = (month, year) => {
  //     setFilter({ month, year });
  // };

  // const handleFilterChange2 = (month, year) => {
  //     setFilterTwo ({ month, year });
  // };

  const [filter, handleFilterChange] =  useFilter({month:'10', year:'2023'});

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [month, setMonth] = useState(1); // Default month, can be changed dynamically

  useEffect(() => {
    fetch("https://ovision.cleverpoint.gr/api/cods")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        filterDataByMonth(data, month);
      });
  }, [month]);

  const filterDataByMonth = (data, month) => {
    const filtered = data
      .map((item) => ({
        ...item,
        monthlyCods: item.monthlyCods.filter((m) => m.month === month),
      }))
      .filter((item) => item.monthlyCods.length > 0);

    setFilteredData(filtered);
  };

  return (
    <div>
      <ServicesSummary
      filter = {filter}
      />
    </div>
  );
};

 

export default TestPage;
