import React, { useEffect, useState } from "react";
import Card from "./Card";
import { IoIosStar } from "react-icons/io";
import useDataWrapper from "../Hooks/useDataWrapper";

const RatingsSummary = ({
  baseEndpoint,
  dynamicSegments,
  dataMethod,
  componentType,
  filter,
}) => {
  const { data, isPending, error } = useDataWrapper({
    baseEndpoint: baseEndpoint,
    dynamicSegments: dynamicSegments,
    dataMethod: dataMethod,
    filter: filter,
    componentType: componentType,
  });


  
  // Initialize state variables
  const [time, setTime] = useState(0);
  const [behaviour, setBehaviour] = useState(0);
  const [serviceQuality, setServiceQuality] = useState(0);
  const [overallAverage, setOverallAverage] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);

  useEffect(() => {
    const item = data && data.length > 0 ? data[0] : null;

    const defaultRatingValue = "-";

    let ratings = null;
    if (item) {
      if (item.MonthlyRatings && item.MonthlyRatings.length > 1) {
        ratings = item.YearlyRatings;
      } else if (item.MonthlyRatings && item.MonthlyRatings.length === 1) {
        ratings = item.MonthlyRatings[0].MonthRatings;
      }

      if (ratings) {
        setTime(ratings.Time || defaultRatingValue);
        setBehaviour(ratings.Behaviour || defaultRatingValue);
        setServiceQuality(ratings.ServiceQuality || defaultRatingValue);
        setOverallAverage(ratings.OverallAverage || defaultRatingValue);
        setTotalRatings(ratings.TotalRatings || defaultRatingValue);
        }else {
        	setTime(defaultRatingValue);
        	setBehaviour(defaultRatingValue);
        	setServiceQuality(defaultRatingValue);
        	setOverallAverage(defaultRatingValue);
        	setTotalRatings(defaultRatingValue);
        }
      
    } else {
      setTime(defaultRatingValue);
      setBehaviour(defaultRatingValue);
      setServiceQuality(defaultRatingValue);
      setOverallAverage(defaultRatingValue);
      setTotalRatings(defaultRatingValue);
    }
  }, [data]);

  if (isPending) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    data && (
      <>
        <div>
          <Card>
            <div className="flex justify-between mb-2">
              <div>
                <p className="subtilte">Συνολικές Αξιολογήσεις</p>
                <h2 className="card-title text-2xl font-semibold">
                  {totalRatings}
                </h2>
              </div>
              <div className="text-right">
                <p className="subtilte">M.O.</p>
                <div className="flex items-center gap-2 content-center">
                  <IoIosStar className="text-2xl text-amber-400" />
                  <h2 className="card-title text-2xl font-bold">
                    {overallAverage}
                  </h2>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="flex justify-between mt-2">
              <div>
                <p className="subtitle">Ποιότητα εξυπηρέτησης</p>
                <p className="card-title text-xl font-medium">
                  {serviceQuality}
                </p>
              </div>
              <div>
                <p className="subtitle">Συμπεριφορά υπαλλήλων</p>
                <p className="card-title text-xl font-medium">{behaviour}</p>
              </div>
              <div>
                <p className="subtitle">Ταχύτητα εξυπηρέτησης</p>
                <p className="card-title text-xl font-medium">{time}</p>
              </div>
            </div>
          </Card>
        </div>
      </>
    )
  );
};

export default RatingsSummary;
