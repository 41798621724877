import React, { useState, useEffect } from 'react';
import ServicesCleverPoint from '../Components/FilterCharts/ServicesCleverPoint.js';
import CodsChartFilter from '../Components/FilterCharts/CodsChartFilter.js';
import MainLayout from '../Components/MainLayout.js';
import ServicesSummaryFilter from '../Components/ServicesSummaryFilter.js';

const Dashboard = () => {
    const contractorName = localStorage.getItem('ContractorName');

    const [contractorId, setContractorId] = useState();
    useEffect(() => {
        if (contractorId !== undefined) {
			setContractorId(contractorId)
            localStorage.clear();
            localStorage.setItem('contractorId', contractorId);
        }
    }, [contractorId]);

    return (
        <MainLayout>
            <div>{contractorName}</div>
            <div className="grid gap-4 mt-5">
                <ServicesSummaryFilter />
            </div>

            <div className="grid md:grid-cols-2 gap-4 mt-5">
                <ServicesCleverPoint />
                <CodsChartFilter />
            </div>
        </MainLayout>
    );
};

export default Dashboard;
