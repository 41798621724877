import React from 'react'
import useFetch from '../../../Hooks/useFetch';
import LineChart from '../LineChart';
import * as chartUtils from '../../../dataUtils/dataTrasformation'; 
import BarChart from '../BarChart';
import useDataWrapper from '../../../Hooks/useDataWrapper';

const ApexChartWrapper = ({baseEndpoint, title, dataMethod,dynamicSegments, type, filter, height}) => {
	
	const { data, isPending, error } = useDataWrapper({
		baseEndpoint : baseEndpoint,
		dynamicSegments : dynamicSegments,
		filter : filter,
		dataMethod: {dataMethod},
		componentType : 'chart'
	  });
	
	let chartSeries;

	if (data && dataMethod && typeof chartUtils[dataMethod] === 'function') {
        chartSeries = chartUtils[dataMethod](data);
    }

	return (
		<div>
		{ error && <div>{ error }</div> }
		{ isPending && <div>Loading...</div> }
		{
		(data && type==='line') && <LineChart chartSeries={chartSeries}  title={title} type={type}/>
		}
		{
		(data && type==='bar') && <BarChart chartSeries={chartSeries}  title={title} type={type} height={height}/>
		}
		{
		(data && type==='area') && <LineChart chartSeries={chartSeries}  title={title} type={type}/>
		}
		</div>
	)
}

export default ApexChartWrapper