// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children}) => {

  const [user, setUser] = useState((localStorage.getItem('isAuth') === 'true'));

  let loginUrl = 'https:/dev.cleverpoint.gr/api/v1/Session/Login/CleverPointAnalytics'
  

  const setExpiration = () => {
    const expirationTime = new Date().getTime() + 10 * 60 * 1000;
    localStorage.setItem('authExpiration', expirationTime.toString());
  };

  const checkExpiration = () => {
    const expirationTime = localStorage.getItem('authExpiration');
    const now = new Date().getTime();
    if (now > parseInt(expirationTime, 10)) {
      logout();
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(`${email}:${password}`),
        },
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Login failed: ${errorData}`);
      }
      const data = await response.json();

      setUser(true);
      console.log(data)
      localStorage.setItem('isAuth', 'true');
      localStorage.setItem('authToken', data.Content.AccessToken);
      localStorage.setItem('ContractorId', data.Content.UserData.ContractorId);
      localStorage.setItem('ContractorName', data.Content.UserData.ContactorName);
      setExpiration();
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };

  const logout = async () => {
    
    const logoutUrl = 'https:/dev.cleverpoint.gr/api/v1/Session/Logout'

    try {
      const authToken = localStorage.getItem('authToken')
      const response = await fetch(logoutUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.text();
        clearSession();
        throw new Error(`Logout failed: ${errorData}`);
      }
      clearSession();
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  const clearSession = () => {
    setUser(false);
    localStorage.removeItem('isAuth');
    localStorage.removeItem('authToken');
    localStorage.removeItem('authExpiration');
    localStorage.removeItem('ContractorId');
    localStorage.removeItem('ContractorName');
  }

  useEffect(() => {
    checkExpiration();
    const isAuth = localStorage.getItem('isAuth') === 'true';
      setUser(isAuth);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, setExpiration}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
