import React from 'react'
import useFetch from './useFetch';
import * as chartUtils from '../dataUtils/dataTrasformation'; 


const useDataWrapper = ({baseEndpoint,	dynamicSegments, dataMethod, componentType, filter, serviceType}) => {

	const headers = {
		"x-api-key" : "4A9ACAC560504E778EC39D05C4102A0D",
		"x-contractor-id" : String(localStorage.getItem('ContractorId'))
	}
	
	const baseUrl = 'https://ovision.cleverpoint.gr/api/';
	const urlSegments = [baseEndpoint, ...dynamicSegments].join('/');

	const params = new URLSearchParams();

	if (filter) {
		if (filter.month !== '') params.append('month', filter.month);
	}

	const fullUrl = `${baseUrl}${urlSegments}${(params.toString() ? `?${params}` : '' )}`;

	const { data, isPending, error } = useFetch(fullUrl, filter, serviceType, headers);

  let processedData = null;

  if (data) {
	if (componentType === 'chart' && dataMethod && typeof chartUtils[dataMethod] === 'function') {
		processedData = chartUtils[dataMethod](data);	
	  }else {
		processedData= data;
	  }
  }
  return {data: processedData, isPending, error}
}
export default useDataWrapper