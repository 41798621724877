import './App.css';
import React, { useEffect, useState } from 'react';
import Dashboard from './Pages/Dashboard';
import TestPage from './Pages/TestPage'
import NotFound from './Pages/NotFound';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import StationData from './Pages/StationData';
import Stations from './Pages/Stations';
import Reports from './Pages/Reports';
import CheckAuth from './Components/CheckAuth';
import LoginPage from './Pages/Login';
import Login from './Pages/Login';
import { AuthProvider, useAuth } from './Components/AuthContext';
import VoltonData from './Pages/VoltonData';

function App() {

    return (
      <AuthProvider>
        <Router>
        <div children className='App'>
          <div className='content'>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/volton" element={<VoltonData/>} />
                <Route element={<CheckAuth/>}>
                  <Route  path='/' element={<Dashboard/>}/>
                  <Route  path='/reports' element={<Reports/>}/>
                  <Route  path='/test' element={<TestPage/>}/>
                  <Route  path='/stations' element={<Stations/>}/>
                  <Route  path='*' element={<NotFound/>}/>
                  <Route  path='/station/:id' element={<StationData/>}/>
                  <Route
                    path="test"
                    element={<TestPage />}
                    />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
          </div>
        </div>
      </Router>
      </AuthProvider>
    );
}

export default App;
