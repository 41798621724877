import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = ({chartSeries, title, type  }) => {

	const series = chartSeries.data

	const options = {
		
		chart: {
			zoom: {
				enabled: true
			},
			animations: {
				enabled: true,
				easing: 'linear',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 100
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350
				}
			},
			plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                }
            },
			dataLables : {
				enabled:false
			}
        },
		colors: ['#009cad', '#1e4079', '#546E7A'],
		stroke: {
			curve: 'smooth',
			width:1
		},
		fill: {
			type: 'color' //Mporw na valw kai gradient
		},
		title: {
			text: title,
			align: 'left'
		  },
		  grid: {
			row: {
			  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
			  opacity: 0.3
			},
		  },
        xaxis: {
            //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug', 'Sept', 'Oct', 'Nov' ,'Dec']
            categories: chartSeries.labels
        },
		yaxis: {
            show:true
        },
		legend: {
				show: true,
				showForSingleSeries: false,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: 'bottom',
				horizontalAlign: 'center', 
				floating: false,
				fontSize: '14px',
				fontFamily: 'Geologica',
				fontWeight: 200,
				inverseOrder: false,
				tooltipHoverFormatter: undefined,
				customLegendItems: [],
				offsetX: 0,
				offsetY: 0,
				labels: {
					colors: undefined,
					useSeriesColors: false
				},
				markers: {
					fillColors: undefined,
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				},
				itemMargin: {
					horizontal: 5,
					vertical: 0
				},
				onItemClick: {
					toggleDataSeries: true
				},
				onItemHover: {
					highlightDataSeries: true
				},
			}
		  }
		

    return (
		<>
        <ReactApexChart options={options} series={series} type={type} height={350} />
		</>
    );
};

export default LineChart;
