import React from 'react';
import CardWithFilter from '../CardWithFilter';
import useFilter from '../../Hooks/useFilter';
import ApexChartWrapper from '../Charts/chartslogic/ApexChartWrapper';
import YearMonthFilter from '../YearMonthFilter';

const ServicesCleverPoint = () => {
    let dt = new Date();
    let myYear = dt.getFullYear();

    const [filter, handleFilterChange] = useFilter({
        month: '',
        year: YearMonthFilter.defaultProps.defaultYear,
    });

    return (
        <div>
            <CardWithFilter
                title="Υπηρεσίες Clever Point"
                subtitle="Γράφημα συνολικών κινήσεων"
                filterUI="both"
                filterType="dates"
                filter={filter}
                onFilterChange={(newMonth, newYear) =>
                    handleFilterChange({ month: newMonth, year: newYear })
                }
            >
                <div className="mt-4">
                    <ApexChartWrapper
                        baseEndpoint="Contractors"
                        dynamicSegments={['GetAll', filter.year]}
                        dataMethod="contractorServices"
                        type="bar"
                        filter={filter}
                        componentType="chart"
                        height="400"
                    />
                </div>
            </CardWithFilter>
        </div>
    );
};

export default ServicesCleverPoint;
